/* 
*******************
General Rules
*******************
*/

#root {
  background-image: linear-gradient(180deg, #0093e9 0%, #80d0c7 100%);
}
.eight-bit-font {
  font-family: EndlessBossBattleRegular;
}
/* BROKEN NES CSS RULE */
.nes-container.is-rounded {
  background-color: white;
  border-image-repeat: unset;
}
/* BROKEN NES CSS RULE */
.nes-btn {
  font-family: EndlessBossBattleRegular;
  border-image-repeat: unset;
}
/* BROKEN NES CSS RULE */
.nes-input {
  border-image-repeat: unset;
}

.center-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

/* 
**************************
Rules for different pages
**************************
*/

.layout-container {
  display: grid;
  min-height: 100vh;
  grid-template-rows: min-content auto min-content;
  gap: 10px;
}

.home-page-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  height: 100%;
}

.background-map-image {
  max-width: 35vw;
  max-height: 65vh;
}

.content-navbar {
  font-family: EndlessBossBattleRegular;
  display: flex;
  flex-direction: column;
  row-gap: 3px;
}

header {
  .navbar {
    border-width: 8px;
    margin-top: 10px !important;
  }
}

.quiz-over-container {
  height: 100%;

  & h2 {
    font-family: EndlessBossBattleRegular;
  }
  .score {
    font-family: monospace;
  }

  .nes-container {
    background-color: white;
  }
  .icons {
    display: flex;
    align-items: center;
  }
}
.config-page-container {
  height: 100%;
  font-family: monospace;

  & h1 {
    font-family: EndlessBossBattleRegular;
  }

  & button {
    margin-top: 10px;
  }
  .form-sections {
    display: grid;
    grid-template-rows: 1fr 1fr;

    .title {
      margin-bottom: 10px;
      font-size: medium;
    }

    .quiz-form-labels {
      display: flex;
      flex-direction: column;
    }
  }
}

.landing-page-container {
  height: 100%;
  font-family: EndlessBossBattleRegular;

  .nes-container {
    display: grid;
    grid-template-rows: 1fr 1fr;

    .buttons {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 2px;

      & span {
        margin-right: 5px;
      }
    }
  }
  .nes-btn {
    font-family: monospace;
  }
}

.active-quiz-container {
  height: 100%;
  & span {
    font-size: small;
  }

  .question {
    font-family: monospace;
    font-size: x-large;
  }
}

.card {
  width: 95vw;
  border: 5px solid rgb(33, 37, 41);

  .card-header {
    background-color: #fff;
    font-family: EndlessBossBattleRegular;
    /* TEMPORARY */
    display: grid;
    grid-template-columns: 1fr 10fr;
    grid-column-gap: 10px;
    .title-temp-container {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .card-body {
    background-color: #fff;
    row-gap: 10px;
  }
  .card-footer {
    background-color: #fff;
    font-family: monospace;
    min-height: 50px;
    max-height: 50px;
  }
}

/* On screens bigger than phones */
@media only screen and (min-width: 768px) {
  header {
    display: flex;
    justify-content: center;

    .navbar {
      width: 85%;
    }
  }

  .card {
    width: 60vw;
  }

  .grammar-explanation-page-container {
    margin-left: 10px;
    margin-right: 10px;
  }

  .active-quiz-container {
    & span {
      font-size: large;
    }
  }
}

/* EXPLANATION PAGES */
.grammar-explanation-page-container {
  .row-1-with-5-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

    & button {
      height: 90%;
    }
  }

  .row-1-with-6-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;

    & button {
      height: 90%;
    }
  }

  .row-1-with-3-buttons {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    & button {
      height: 90%;
    }
  }

  .row-2 {
    margin-top: 5px;
    padding: 0px;
  }
  font-family: monospace;
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto;

  & th {
    background-color: #37b5ff;
  }
  & td,
  th {
    border: 1px solid #dddddd85;
    padding: 5px;
  }

  & tr:nth-child(even) {
    background-color: #acdefc;
  }

  & td:hover {
    background-color: #ddd;
  }

  /* These rules apply to the html files that are in the public folder */
  .special-table {
    width: 100%;

    & td {
      border: none;
    }
    .bg-white {
      background-color: white;
    }
  }
}

.verb-table-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.table-groups {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
/* On screens bigger than phones */
@media only screen and (min-width: 768px) {
  .table-groups {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.about-page-container {
  .nes-container {
    width: 65%;
    font-family: monospace;
  }
}
